var isMobile = $("body").hasClass("mobile") ? true : false;

$(function() {
    if ($("#astrologyFormWrapper").length && typeof astroUserId !== 'undefined' && typeof astroApiKey !== 'undefined') {
        setTimeout(function () {
            updateAddToCartBtn();
            $(".configurator_add_to_cart").show();
            $( "#astrologyFormWrapper" ).show();

                $("#astrologyBirthdate, #astrologyHour, #astrologyMinute, #astrologyAddress").focusin(function(){
                    $("#add_basket_wrapper #button_add").hide();
                    $("#bloc_add_basket #btn_add_cart").hide();
                });

                document.getElementById("astrologyForm").addEventListener("focusout", Focusout);
                function Focusout() {
                    if ( $("#astrologyForm #astrologyBirthdate").val() || $("#astrologyForm #astrologyAddress").val() || $("#astrologyForm #astrologyHour").val() || $("#astrologyForm #astrologyMinute").val() ) {
                    } else { 
                        $("#add_basket_wrapper #button_add").show();
                        $("#bloc_add_basket #btn_add_cart").show();
                    }
                }

            $("#astrologyBirthdate, #astrologyAddress, #astrologyHour, #astrologyMin").change(function() {
                if ($(this).parent().hasClass('is-empty')) {
                    $(this).parent().removeClass('is-empty');
                }

                $('.astralPortrait__input').on("input", function() {
                    if ($(this).val() !== '') {
                        $(this).addClass('is-not-empty');
                    } else if ($(this).hasClass('is-not-empty')) {
                        $(this).removeClass('is-not-empty');
                    }
                });
            });

            Astrology.init();
        }, 1000);

        $("#astrologyHour, #astrologyMinute").keyup(function (event) {
            if ($(this).attr('id') == "astrologyMinute") {
                if ($(this).val() > 59) {
                    $(this).val(59);
                }
            } else {
                if ($(this).val().length == 1 && event.key > 2) {
                    $(this).val('0' + $(this).val());
                    $("#astrologyMinute").focus();
                } else if ($(this).val().length == 2) {
                    if ($(this).val() > 23) {
                        $(this).val(23);
                    }
                    $("#astrologyMinute").focus();
                }
            }
        });

        $("#astrologyHour, #astrologyMinute").keydown(function (event) {
            if (event.key == "e") {
                return false;
            }
            if ($(this).attr('id') == "astrologyMinute" && $(this).val().length > 1 && 0 <= event.key && event.key <= 9) {
                return false;
            }
        });
    }

    if ($("body").hasClass("ma_bougie_sur_mesure") && !$(".productInfos__format .format.selected").length) {
        $(".productInfos__format .format").first().click();
    }
}); 

/**
 * Updates the configuration format based on the selected format.
 * @function updateConfigFormat
 */
function updateConfigFormat() {
    if (!$(this).hasClass('selected')) {
        $(".productInfos__format .format").removeClass("selected");
        $(this).addClass('selected');
        $("#format").val($(this).data('id'));
        loadConfigProduct();
    }
}

/**
 * Loads the configuration for the product.
 * Retrieves attributes from select elements with the class "configurator__select".
 * Updates the add to cart button.
 * Makes an AJAX request to load the configured product based on the selected attributes.
 * Updates the page content with the loaded product information.
 * Handles error cases and displays appropriate messages.
 */
function loadConfigProduct() {
    var attributes = [];
    var elements = $("select.configurator__select");
    for (i = 0; i < elements.length; i++) {
        attributes.push(elements[i].value);
    }
    updateAddToCartBtn();
    $.ajax({
        url : path_relative_root + create_link('ajax_load_config_product'),
		type : 'get',
		data : {"attributes": attributes},
		success : function (res) {
            /* To activate lazyload */
            window.scrollTo(0, $(window).scrollTop() + 1);

            try {
                var parfum = $("#parfum").val();
                res = JSON.parse(res);
                if (res.success == true && Object.keys(res.product).length) {
                    var mainProduct, astralPortrait = false;
                    for (i = 0; i < res.attributes.length; i++) {
                        var attribute = res.attributes[i];
                        if (attribute["attributes_code"].toLowerCase() == 'principal') {
                            mainProduct = true;
                        }
                        if (attribute["attributes_code"].toLowerCase() == Translator.translate('attrib_perso')) {
                            astralPortrait = true;
                        }
                    }
                    $(".pageProduit__publications").html(res.cmsPages);
                    if (mainProduct) {
                        $(".productInfos__desc").html(res.product.description);
                    } else {
                        $(".productInfos__desc").html(Translator.translate("description_for_perfume_" + parfum));
                    }
                    $(".productInfos__title").html(res.product.title);
                    $(".productInfos__subtitle").html(res.color.name);
                    $("#productVisuals").html(res.pictures);
                    $("#productCharacteristics").html(res.characteristics);
                    $(".productInfos__price").html(res.price);
                    $("#produit_id").val(res.product.id);
                    $("#couleurProd").val(res.color.id);
                    $("#prod_couleur").val(res.color.id);
                    $("#tailleProd").val(res.size.id);
                    $("#prod_taille").val(res.size.id);
                    $("#titreObjet").val(res.product.title);
                    $("#prixU").val(res.product.prixU);
                    $(".productInfos__size").html(res.size.name);
                    $(".pageProduit__ritual").show();
                    if (isMobile) {
                        var element = document.getElementById('btn_add_alert');
                        element.setAttribute('data-color', res.color.id);
                        element.setAttribute('data-ref', res.product.ref);
                    }
                    if (astralPortrait) {
                        $("#astrologyContent").show();
                        $("#astrologyFormWrapper").show();
                    } else {
                        $("#astrologyContent").hide();
                        $("#astrologyFormWrapper").hide();
                    }
                    if (res.left_stock > 0) {
                        $("section.astralPortrait").show();
                        $("#product_addcart").show();
                        $("#bloc_add_alert").hide();
                    } else {
                        $("section.astralPortrait").hide();
                        $("#product_addcart").hide();
                        $("#bloc_add_alert").show();
                    }
                    $("#modbox_title").html(Translator.translate('item_added_to_cart'));
                    $("#configurator_error").hide();
                    $(".configurator_add_to_cart").prop('disabled', false);

                    /* Configurator products swiper */
                    if ($('#productVisuals .swiper-slide').length > 1) {
                        var configuratorProdSwiper = new Swiper('#productVisuals', {
                            slidesPerView: 1,
                            pagination: {
                                el: '.swiper-pagination',
                                type: 'bullets',
                                clickable: true,
                            },
                        });
                    }

                } else {
                    // clean form
                    $(".pageProduit__publications").html('');
                    var title = Translator.translate("votre_signe");
                    if ($("#signe").val() > 0) {
                        title = $("#signe option:selected").text();
                    }
                    var format = $(".productInfos__format button.selected").data('id');
                    var subtitle = Translator.translate("votre_parfum");
                    if (parfum > 0) {
                        subtitle = $("#parfum option:selected").text();
                        $(".productInfos__desc").html(Translator.translate("description_for_perfume_" + parfum));
                    }
                    if (parfum === '0') {
                        $(".productInfos__desc").html(Translator.translate("configurator_description_" + format));
                    }
                    var img = '';
                    if (typeof res.default_img !== "undefined" && res.default_img != '') {
                        img = res.default_img;
                        $("#productVisuals").html('<img class="productVisuals__img" src="' + img + '" alt="' + Translator.translate('header_nav_configurator') + '">');
                    } else {
                        $("#productVisuals").html('');
                    }
                    $(".productInfos__title").html(title);
                    $(".productInfos__subtitle").html(subtitle);
                    $("#productCharacteristics").html('');
                    $(".productInfos__price").html('');
                    $("#produit_id").val('');
                    $("#couleurProd").val('');
                    $("#prod_couleur").val('');
                    $("#titreObjet").val('');
                    $("#prixU").val('');
                    $(".pageProduit__ritual").hide();
                    if (isMobile) {
                        var element = document.getElementById('btn_add_alert');
                        element.setAttribute('data-color', '');
                        element.setAttribute('data-ref', '');
                    }
                    $("section.astralPortrait").hide();
                    if ($("#parfum").val() != 0 && $("#signe").val() != 0) {
                        $("#configurator_error").show();
                    }
                    $(".configurator_add_to_cart").prop('disabled', true);
                }
            } catch (e) {
                console.log(e)
                alert(Translator.translate("config_error_get_product"));
            }
		}
    });
}

/**
 * Saves the configuration and adds the product to the cart.
 * @returns {boolean} Returns false if there is an error adding the product to the cart, otherwise returns true.
 */
function saveConfig() {
    if (isMobile && !document.getElementById('prod_info_config')) {
        var produit_id = $("input[name=produit_id]").val();
        var color_id = $("select[name=itm_colour]").val();
        var size_id = $("select[name=itm_size]").val();
        var basket_id = $("input[name=cart_id]").val();
        var unit_price = $("input[name=itm_unitprice]").val();
    } else {
        var produit_id = $("#produit_id").val();
        var color_id = $("#couleurProd").val();
        if (document.getElementById('tailleProd')) {
            var size_id = $("#tailleProd").val();
        } else {
            var size_id = $('input[name="tailleProd"]:checked').val()
        }
        var basket_id = $("#panier_id").val();
        var unit_price = $("#prixU").val();
    }

    var astralPortrait = $("#astralPortrait").val();
    if (astralPortrait == "false" && $("#astrology_results").is(":visible")) {
        var error = Translator.translate("config_error_add_cart");
        if (!isMobile) {
            alert(error);
        } else {
            showModalAfterAddToCart(error);
        }
        return false;
    }
    var dataBasket = {
        configurator: true,
        idprod: produit_id,
        idcouleur: color_id,
        idtaille: size_id,
        panierid: basket_id,
        prixU: unit_price,
        virtual_basket: 'true',
        qte: 1,
        configurator_product: false,
        dataTypeReturn: 'json'
    };
    var parfum = $(".productInfos__subtitle").html().replace('\t\n', '').trim();
    var configurator = {
        product_id: produit_id,
        fk_taille_id: size_id,
        ean_astral: astralPortrait,
        perfume: parfum
    };

    var send = true;
    if (astralPortrait) {
        var exists = checkIfConfigExist({
            configurator: configurator,
            produitId: produit_id,
            couleurId: color_id,
            tailleId: size_id
        });
        if (!exists) {
            dataBasket.panierid = 0;
        } else {
            dataBasket.panierid = exists;
            send = false;
        }
    } else if (isMobile) {
        dataBasket.configurator = false;
    }

    if (send) {
        var response = sendConfigToBasket(dataBasket);
    } else {
        var response = updatePanier(dataBasket.panierid);
    }

    if (response.error == 0) {
        if (isMobile && !$("#astralPortrait").val()) {
            updateMobileCart(response);
        }

        if (send) {
            $.ajax({
                url : path_relative_root + create_link('ajax_save_config'),
                type : 'post',
                data : {
                    configurator: JSON.stringify(configurator),
                    default_img: true,
                    update_session: 1,
                    basket_id: response.panier_virtuel_id
                },
                success : function (res) {
                    res = JSON.parse(res);
                    configUpdateCart(res.success, response);
                }
            });
        } else {
            configUpdateCart(true, response);
        }
    } else {
        var error = Translator.translate("config_error_add_cart");
        if (!isMobile) {
            alert(error);
        } else {
            showModalAfterAddToCart(error);
        }
    }
}

/**
 * Updates the panier (basket) with the specified basket ID.
 * @param {number} basket_id - The ID of the basket to update.
 * @returns {boolean} - The response from the server indicating the success of the update.
 */
function updatePanier(basket_id) {
    let response = false;
    $.ajax({
        url: path_relative_root + create_link('ajax_update_basket_config'),
        type: "post",
        dataType: 'json',
        async: false,
        data: {basket_id: basket_id},
        success: function (res) {
            response = res;
        }
    });
    return response;
}

/**
 * Updates the cart based on the success and response parameters.
 *
 * @param {boolean} success - Indicates whether the update was successful or not.
 * @param {any} response - The response received after the update.
 */
function configUpdateCart(success, response) {
    if (success) {
        if (!isMobile) {
            var response_basket = ajax_file(path_relative_root + create_link('ajax_show_basket'));

            $("#cart_top").html(response_basket);

            var count_bask = $('#cart_top .cart').attr('data-cart');

            if (count_bask > 2) {
                $("#cart_top .cart_product_listing").overlayScrollbars({});
            }

            newshowmodal();
        } else {
            updateMobileCart(response);
        }
    } else {
        var error = Translator.translate("config_error_save_config");
        if (!isMobile) {
            alert(error);
        } else {
            showModalAfterAddToCart(error);
        }
    }
}

/**
 * Checks if a configuration exists.
 *
 * @param {Object} data - The data to be sent in the AJAX request.
 * @returns {boolean} - The response indicating whether the configuration exists or not.
 */
function checkIfConfigExist(data) {
    let response = false;
    $.ajax({
        url: path_relative_root + create_link('ajax_check_config'),
        type: "post",
        dataType: 'json',
        async: false,
        data: data,
        success: function (res) {
            response = res;
        }
    });
    return response;
}

/**
 * Sends the configuration data to the basket.
 * @param {Object} data - The configuration data to send.
 * @returns {Object} - The response from the server.
 */
function sendConfigToBasket(data) {
    let response = {};
    $.ajax({
        url: path_relative_root + create_link('ajax_update_basket'),
        type: "post",
        dataType: 'json',
        async: false,
        data: data,
        success: function (res) {
            response = res;
            if (response.datalayer_object !== undefined) {
                pushIntoDatalayer(response.datalayer_object);
            }
        }
    });
    return response;
}

/**
 * Updates the mobile cart based on the response.
 * @param {Object} response - The response object containing the number of articles.
 */
function updateMobileCart(response) {
    var cart_btn = document.getElementById('cart_btn');
    if (response.nb_article == 0) {
        if (cart_btn) {
            cart_btn.classList.add('none');
            cart_btn.classList.remove('count');
            cart_btn.removeAttr('data-cart');
        }
    } else if (response.nb_article != false) {
        if (cart_btn) {
            cart_btn.classList.add('count');
            cart_btn.classList.remove('none');

            // If there is no defined product in data-cart
            if (!cart_btn.getAttribute('data-cart')) {
                cart_btn.setAttribute('data-cart', parseInt(response.nb_article))
            } else {
                // Get the total number of products in the cart
                var initialCount = cart_btn.getAttribute('data-cart');
                // Add the chosen quantity to the total count
                var finalCount = parseInt(initialCount) + 1;
                cart_btn.setAttribute('data-cart', finalCount);
            }
        }

        window.setTimeout(function() {
            var loader = $('.loader');
            if (loader.hasClass('loading')) {
                loader.removeClass('loading');
            } else {
                loader.parent().removeClass('loading');
            }
        }, 400);
        modBoxOpen.call(document.getElementById('item_added'));
    }
}

/**
 * Displays a modal after adding an item to the cart.
 *
 * @param {string} texte - The text to be displayed in the modal.
 */
function showModalAfterAddToCart(texte) {
    if (typeof texte !== 'undefined') {
        $('#item_added').find('.modbox_title').html(texte);
    }
    window.setTimeout(function() {
        var loader = $('.loader');
        if (loader.hasClass('loading')) {
            loader.removeClass('loading');
        } else {
            loader.parent().removeClass('loading');
        }
    }, 400);
    modBoxOpen.call(document.getElementById('item_added'));
}

/**
 * Updates the "Add to Cart" button based on the selected values in the configurator.
 */
function updateAddToCartBtn() {
    var btn = $(".configurator_add_to_cart");
    var astro = $(".astralPortrait.myCandle");
    if ($("#signe").val() > 0 && $("#parfum").val() > 0) {
        if (btn.prop('disabled')) {
            astro.show();
            btn.prop('disabled', false);
        }
    } else {
        astro.hide();
        btn.prop('disabled', true);
    }
}

var previousUrl = document.referrer;
var previousPath = previousUrl.substring(previousUrl.lastIndexOf("/") + 1);

let hasHash = checkHashAstralPortrait();

// we check if we come from the sign calculator page or if we come from the astral portrait newsletter
if (previousPath == create_link('calculer_mon_signe') || hasHash) {
    document.addEventListener("DOMContentLoaded", function() {
        var astrologyShowButton = document.getElementById("astrologyShowButton");
        var astrologySubmitButton = document.getElementById("astrologySubmitButton");
        astrologyShowButton.click();
        astrologySubmitButton.click();
    });
}

/**
 * Displays the astral portrait form.
 */
function displayAstralPortraitForm() {
    $("#astrologyForm").show();
    $("#astrologyForm").addClass('is-open');
    $("#astrologyShowButton").hide();

    if ($("#productInfos").hasClass('sticky')) {
        $("#productInfos").removeClass('sticky');
    }

    // Inserting sign user input from the 'calculator sign' page
    var previousUrl = document.referrer; 
    var previousPath = previousUrl.substring(previousUrl.lastIndexOf("/") + 1);
    if (previousPath == create_link('calculer_mon_signe') || hasHash) {
        // we check if we come from the sign calculator page or if we come from the astral portrait newsletter
        document.getElementById('astrologyBirthdate').value = localStorage.getItem('birthdayDate');
        document.getElementById('astrologyAddress').value = localStorage.getItem('birthdayCity');
        document.getElementById('astrologyHour').value = localStorage.getItem('birthdayHour');
        document.getElementById('astrologyMinute').value = localStorage.getItem('birthdayMin');
        document.getElementById('astrologyLatitude').value = localStorage.getItem('astrologyLatitude');
        document.getElementById('astrologyLongitude').value = localStorage.getItem('astrologyLongitude');        
    }
}

/**
 * Resets the astral portrait form by hiding/showing elements and clearing input values.
 * Also updates the onclick event for the add to cart button based on the device and page context.
 */
function resetAstralPortraitForm() {
    $("#astrologyShowButton").show();
    $("#astrologyForm").hide();
    $("#astrology_results").hide().removeClass("show");
    $("#astrologyForm input").val('');
    $("#astrology_results .to_clean").html('');
    $("#astralPortrait").val(false);

    $(".astralPortrait__errorMessage").html('');
    $(".astralPortrait__errorMessage").hide();

    if (isMobile) {
        $("#button_add, #btn_add_cart").attr('onclick', "checkFormUpdateCart.call(this, event);");
    } else {
        if (document.getElementById('prod_info_config')) { // page du configurateur
            $("#btn_add_cart").attr('onclick', 'waitingAddToBasket.call(this, event, document.getElementById(\'prod_info_config\'));');
        } else {
            $("#btn_add_cart").attr('onclick', 'waitingAddToBasket.call(this, event, this.form);');
        } 
    }
}

/**
 * Calculates the astral portrait based on the user's inputs.
 * 
 * @returns {void}
 */
function calculateAstralPortrait() {

    if (typeof astroUserId !== 'undefined' && typeof astroApiKey !== 'undefined') {

        if ($(".astralPortrait__errorMessage").length) {
            $(".astralPortrait__errorMessage").hide();
        }

        // We will pass some sign user inputs 
        var birthdayDate = document.getElementById('astrologyBirthdate').value;
        var birthdayCity = document.getElementById('astrologyAddress').value;
        var birthdayHour = document.getElementById('astrologyHour').value;
        var birthdayMin = document.getElementById('astrologyMinute').value;
        var astrologyLatitude = document.getElementById('astrologyLatitude').value;
        var astrologyLongitude = document.getElementById('astrologyLongitude').value;
        localStorage.setItem('birthdayDate', birthdayDate);
        localStorage.setItem('birthdayCity', birthdayCity);
        localStorage.setItem('birthdayHour', birthdayHour);
        localStorage.setItem('birthdayMin', birthdayMin);
        localStorage.setItem('astrologyLatitude', astrologyLatitude);
        localStorage.setItem('astrologyLongitude', astrologyLongitude);

        /**
         * Calculate astral portrait if mandatories inputs are not empty
         */
        if (emptyValidators()) {

            // get Form Data
            var birthdate = $("#astrologyBirthdate").val().split(/-/);
            var hour = ($("#astrologyHour").val() != '') ? parseInt($("#astrologyHour").val()) : 0;
            var min = ($("#astrologyMinute").val() != '') ? parseInt($("#astrologyMinute").val()) : 0;
            var address = $("#astrologyAddress").val();
            var latitude = ($("#astrologyLatitude").val() != '') ? $("#astrologyLatitude").val() : false;
            var longitude = ($("#astrologyLongitude").val() != '') ? $("#astrologyLongitude").val() : false;

            var data = {
                year: birthdate[0], 
                month: birthdate[1], 
                day: birthdate[2],
                hour: hour,
                min: min
            }

            if (longitude != false && latitude != false && address != '') {
                var api = "https://json.astrologyapi.com/v1/timezone_with_dst";
                var dataAPI  = {
                    latitude: latitude,
                    longitude: longitude,
                    date: birthdate[1] + "-" + birthdate[2] + "-" + birthdate[0] // format mm-dd-yyyy
                }
                //get time zone
                $.ajax({
                    url: api,
                    method: "POST",
                    dataType:'json',
                    headers: {
                        "authorization": "Basic " + btoa(astroUserId + ":" + astroApiKey),
                        "Content-Type":'application/json'
                    },
                    data: JSON.stringify(dataAPI)
                }).then(function(response) {
                    addInLogFile(api, data, response);
                    data['lat'] = latitude;
                    data['lon'] = longitude;
                    data['tzone'] = response.timezone;
                    getPortraitAstral(data, true);
                }, function(err){
                    addInLogFile(api, data, err.responseJSON, err.status);
                    return err;
                });
            } else {
                // default value (mandatory for api)
                data['lat'] = 0;
                data['lon'] = 0;
                data['tzone'] = 0;
                getPortraitAstral(data, false);
            }
        }
    }
}

var Astrology = {
    init: function() {
    let elm = document.getElementById('astrologyAddress');
        let options = {
            types: ['locality'],
            fields: ['place_id', 'geometry']
        };

        this.places = new google.maps.places.Autocomplete(elm, options);

        google.maps.event.addListener(this.places, 'place_changed', this.onPlaceChange.bind(this));
        elm.addEventListener('keydown', (evt) => {
            if (evt.keyCode === 13) {
                evt.preventDefault();
            }
        });
    },
    onPlaceChange: function() {
        let place = this.places.getPlace();
        var latitude = place.geometry.location.lat();
        var longitude = place.geometry.location.lng();
        $("#astrologyLatitude").val(latitude);
        $("#astrologyLongitude").val(longitude);
    }
}

/**
 * Retrieves the portrait astral based on the provided data and moonPortrait flag.
 *
 * @param {Object} data - The data object to be sent in the AJAX request.
 * @param {boolean} moonPortrait - A flag indicating whether the moon portrait should be included.
 * @returns {Object} - The results of the AJAX request.
 */
function getPortraitAstral(data, moonPortrait) {

    var api = "https://json.astrologyapi.com/v1/planets/tropical";
    addInLogFile(api, data, moonPortrait);

    $.ajax({
        url: api,
        method: "POST",
        dataType:'json',
        headers: {
            "authorization": "Basic " + btoa(astroUserId + ":" + astroApiKey),
            "Content-Type":'application/json'
        },
        data: JSON.stringify(data)
    }).then( function(response) {
        addInLogFile(api, data, response);
        var solaire = response[0].sign;
        var lunaire = response[1].sign;
        var ascendant = response[10].sign;

        if (moonPortrait) {
            var path = create_link({
                'routeId': 'astrology_get_ean_moon',
                'sunSign': solaire, 
                'moonSign': lunaire, 
                'ascendant': ascendant
            });
        } else {
            var path = create_link({
                'routeId': 'astrology_get_ean_sun',
                'sunSign': solaire
            });
        }

        var astrologyEmail = '';
        if (document.getElementById('astrologyEmail') !== null) {
            astrologyEmail = document.getElementById('astrologyEmail').value;
        }

        var astrologyOptin = 0;
        if (document.getElementById('astrologyOptin') !== null) {
            astrologyOptin = (document.getElementById('astrologyOptin').checked) ? '1' : '0';
        }

        localStorage.setItem('astrologyEmail', astrologyEmail);
        localStorage.setItem('astrologyOptin', astrologyOptin);

        let ajaxDatas = {
            birthday: {
                date: localStorage.getItem('birthdayDate'),
                hour: localStorage.getItem('birthdayHour'),
                min: localStorage.getItem('birthdayMin')
            },
            city: {
                name: localStorage.getItem('birthdayCity'),
                latitude: localStorage.getItem('astrologyLatitude'),
                longitude: localStorage.getItem('astrologyLongitude')
            },
            email: astrologyEmail,
            optin: astrologyOptin,
        };

        $.ajax({
            type: 'POST',
            url: path_relative_root + path,
            data: ajaxDatas,
            success: function (results) {
                results = JSON.parse(results);

                addInLogFile(path_relative_root + path, '', results);

                if (response.ean == "false") {
                    var errorTranslated = Translator.translate("astrology_error_get_ean");
                    if (!isMobile) {
                        alert(errorTranslated);
                    } else {
                        showModalAfterAddToCart(errorTranslated);
                    }
                    return false;
                }

                // display results
                var sunSign = Translator.translate("astrology_" + results.sunSign);
                $("#astrologyForm").hide();
                $("#astrology_results").show().addClass("show");
                $("#bloc_add_basket #btn_add_cart").show();
                $("#add_basket_wrapper #button_add").show();
                $("#astrology_solaire").html(sunSign);
                $("#astrology_element").html(Translator.translate("astrology_" + results.element));

                var ascendantItem = $('.astralPortrait__resultItem--element'); 
                /**
                 * Set element icon
                 */
                ascendantItem.removeClass('astralPortrait__resultItem astralPortrait__resultItem--element bgSprite bfr'); 
                ascendantItem.addClass('astralPortrait__resultItem astralPortrait__resultItem--element bgSprite bfr ' + results.element);

                if ($('.astralPortrait__resultsList').hasClass('astralPortrait__resultsList--moonPortrait')) {
                    $('.astralPortrait__resultsList').removeClass('astralPortrait__resultsList--moonPortrait')
                }

                if ($('.astralPortrait__resultsList').hasClass('astralPortrait__resultsList--solarPortrait')) {
                    $('.astralPortrait__resultsList').removeClass('astralPortrait__resultsList--solarPortrait')
                }

                if (moonPortrait) {
                    $("#lunaire").show();
                    $("#ascendant").show();
                    $("#astrology_lunaire").html(Translator.translate("astrology_" + results.moonSign));
                    $("#astrology_ascendant").html(Translator.translate("astrology_" + results.ascendant));
                    $('.astralPortrait__resultsList').addClass('astralPortrait__resultsList--moonPortrait');
                } else {
                    $("#lunaire").hide();
                    $("#ascendant").hide();
                    $('.astralPortrait__resultsList').addClass('astralPortrait__resultsList--solarPortrait');
                }

                if (document.getElementById('prod_info_config')) {
                    $("#btn_add_cart").attr('onclick', 'saveConfig();');
                } else if (isMobile) {
                    $("#button_add").attr('onclick', 'saveConfig();');
                } else {
                    $("#btn_add_cart").attr('onclick', 'saveConfig();');
                }
                $("#astralPortrait").val(results.ean);

                /**
                 * Lightbox redirecting to configurator 
                 */
                var idAttrSignProduct = $("#sign").val();
                var idAttrPerfumProduct = $("#perfum").val();
                var idAttrProduct = $("#sign").val();
                var idAttrSign, idAttrPerfume = false;
                var idAttrFormat = $("#format").val();
                try {
                    var attributes = results.produit.attributes;
                } catch(e)  {
                    var attributes = [];
                }
                if (attributes.length) {
                    for (i = 0; i < attributes.length; i++) {
                        var attribute = attributes[i];
                        let attrCode = attribute["attributes_code"].toLowerCase();
                        if (attrCode == 'parfum') {
                            idAttrPerfume = attribute['value_id'];
                        }
                        if (attrCode == 'signe') {
                            idAttrSign = attribute['value_id'];
                        }
                        if (attrCode == 'format') {
                            idAttrFormat = attribute['value_id'];
                        }
                    }
                }
                var noAccentSunSign = sunSign;
                if (isMobile) {
                    idAttrProduct = $("#sign").val();
                    noAccentSunSign = sunSign.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                }

                if (idAttrSign != idAttrSignProduct) {
                    var astralLBId = 'astralLightbox';
                    var astralLBSigne = $('.astralLightbox__signe'); 
                    var astralLBLink = $('.astralLightbox__link');
                    var arrayAttrId = [parseInt(idAttrPerfumProduct), parseInt(idAttrSign), parseInt(idAttrFormat)];
                    var params = 'attributes=' + arrayAttrId.join(',') + '&astral=' + results.ean;
                    var link = path_relative_root + create_link('ma_bougie_sur_mesure') + '?' + params;

                    astralLBSigne.html(sunSign); 
                    astralLBLink.attr('href', link); 

                    if (isMobile) {
                        openAstralLightbox(true);
                    } else if ($('#' + astralLBId).length) {
                        openMultiShad(astralLBId);
                        $("#shad").addClass("astralLightbox");
                    } 
                }

                /**
                 * Signe calculator page
                 */
                if ($('.astralPortrait').hasClass('signeCalculator')) {
                    var signeCalculatorGif = $('.signeCalculatorGif'); 
                    var signeAsideContent = $('.signeCalculatorAside__content');
                    var signeAsideVisual = $('.signeCalculatorAside__visual');
                    var textContent = $('.astralPortrait__content'); 
                    var signePretitle = $('.astralPortrait__productPretitle'); 
                    var signeVisual = $('.astralPortrait__productVisual'); 
                    var signeTitle = $('.astralPortrait__productTitle'); 
                    var signeSubtitle = $('.astralPortrait__productSubtitle');
                    var signeCta = $('.astralPortrait__resultsCta a');

                    /**
                     * Set diplay of elements
                     */
                    signeCalculatorGif.hide(); 
                    textContent.hide(); 
                    signeAsideVisual.hide(); 
                    signeAsideContent.show(); 

                    /**
                     * Set content
                     */
                    signePretitle.html(results.produit.subtitle);
                    signeVisual.attr('src', results.produit.image);
                    signeTitle.html(results.produit.title);
                    signeSubtitle.html(results.produit.couleur);

                    /**
                     * Set product page redirection
                     */
                    var objet = results.produit.title.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // remove accent
                    if (typeof path_relative_root !== "undefined") {
                        signeCta.attr('href', path_relative_root + create_link({
                            'routeId': 'objects_category_super',
                            'category_super': Translator.translate('wheel_infos_eshop'),
                            'category': Translator.translate('wheel_infos_candles'),
                            'subcategory': Translator.translate('wheel_infos_signs'),
                            'object': objet.toLowerCase()
                        }));
                    }
                }

                return results;
            }
        });
    }, function(err) {
        addInLogFile(api, data, err.responseJSON, err.status);
        var response = err.responseJSON;
        var errorTranslated = Translator.translate("astrology_error_get_ean");
        if (response.error) {
            var context = response.error[0].context;
            if (context.key == "year") {
                var errorTranslated = Translator.translate("astrology_error_year", [context.limit]);
            }
        }
        if (!isMobile) {
            alert(errorTranslated);
        } else {
            showModalAfterAddToCart(errorTranslated);
        }
        return err;
    });      

}

/**
 * Adds the specified URL, data, response, and status to the log file.
 *
 * @param {string} url - The URL to be logged.
 * @param {object} data - The data to be logged.
 * @param {object} response - The response to be logged.
 * @param {number} [status=200] - The status code to be logged. Defaults to 200 if not provided.
 */
function addInLogFile(url, data, response, status) {
    if (typeof status === 'undefined') {
        status = 200;
    }

    $.ajax({
        url: path_relative_root + create_link('astrology_add_in_log'),
        method: 'POST',
        data: {
            'url': url,
            'data': data,
            'status': status,
            'response': response
        }
    });
}

/**
 * Validates the input fields for astrology birthdate, address, hour, and minute.
 * If any of the fields are empty or contain invalid values, appropriate error messages are displayed.
 * 
 * @returns {boolean} Returns true if all input fields are valid or empty, otherwise returns false.
 */
function emptyValidators() {
    $("#astrologyBirthdate, #astrologyAddress, #astrologyHour, #astrologyMin, #astrologyEmail").parent().removeClass('is-empty');

    var birthdateValidator = $("#astrologyBirthdate");
    var addressValidator = $("#astrologyAddress");
    var longitudeValidator = $("#astrologyLongitude");
    var latitudeValidator = $("#astrologyLatitude");
    var hourValidator = $("#astrologyHour");
    var minValidator = $("#astrologyMinute");
    var emailValidator = $("#astrologyEmail");

    if (birthdateValidator.val() == '') {
        birthdateValidator.parent().addClass('is-empty');
        $("#birthdateError").html(Translator.translate('astrology_error_date'));
        $("#birthdateError").show();
        return false;
    }

    var hourOk = false;
    var hourValue = hourValidator.val();
    if (hourValue !== '' && 0 <= hourValue && hourValue < 24) {
        hourOk = true;
    }

    var minOk = false;
    var minValue = minValidator.val();
    if (minValue !== '' && 0 <= minValue && minValue < 61) {
        minOk = true;
    }

    var addressOk = false;
    var addressValue = addressValidator.val();
    if (addressValue !== '' && longitudeValidator.val() != '' && latitudeValidator.val() != '') {
        addressOk = true;
    }

    if (emailValidator.is(':visible')) {
        var emailValue = emailValidator.val();
        if (emailValue !== '' && emailValidator[0].checkValidity() && validateEmail(emailValue)) {
            emailOk = true;
        } else {
            $('.astralPortrait__inputContainer.astralPortrait__inputContainer--email').addClass('is-empty');
            $("#emailError").html(Translator.translate('astrology_error_email'));
            $("#emailError").show();
            return false;
        }
    }

    if (hourOk && minOk && addressOk) {
        return true;
    } else if (minValue == '' && hourValue == '' && addressValue == '') {
        return true;
    } else {
        if (!hourOk || !minOk) {
            hourValidator.parent().addClass('is-empty');
        }

        if (!addressOk) {
            addressValidator.parent().addClass('is-empty');
        }

        var message = '';
        if (hourOk && minOk && latitudeValidator.val() == '') {
            message = 'astrology_error_longitude';
            $("#addressError").html(Translator.translate(message));
            $("#addressError").show();
        } else if (addressOk && (!hourOk || !minOk)) {
            message = 'astrology_error_hour';
            $("#timeError").html(Translator.translate(message));
            $("#timeError").show();
        } else {
            message = 'astrology_error_time_address';
        }
        return false;
    }
}

/**
 * Displays a lightbox alert for stock on mobile devices.
 * 
 * @param {HTMLElement} element - The element triggering the alert.
 * @param {string} type - The type of alert to display.
 * @returns {boolean} - Returns false.
 */
function lightboxAlertStockMobile(element, type) {
    lightboxAlertStock(element.getAttribute('data-color'), type); 
    if (typeof(ga) != 'undefined') {
        ga('send', 'event', 'Fiche Produit', 'Disponibilite', element.getAttribute('data-ref'));
    } 
    return false;
}

/**
 * Opens or closes the astral lightbox.
 * @param {boolean} open - Indicates whether to open or close the lightbox.
 */
function openAstralLightbox(open) {
    var astralLightbox = $("#astralLightbox");
    var shade = document.querySelector("#shade");
    var tabElem = document.querySelectorAll("#content_product #astrologySubmitButton");

    if (open) {
        if (!astralLightbox.hasClass("active")) {
            astralLightbox.addClass("active");

            if (isMobile && tabElem.length > 0) {
                modBoxOpen(); 
                shade.replaceWith(shade.cloneNode(true));
            }
        }
    } else {
        astralLightbox.removeClass("active");
    }
}

/**
 * Displays the email form if all empty validators pass.
 */
function displayEmailForm() {
    if (emptyValidators()) {
        $('#astrologyNextButton, .astralPortrait__inputContainer, .astralPortrait__desc').hide();
        $('.email_form_component').show();
    }
}

/**
 * Checks if the URL has a hash parameter called "hash_astral_portrait" and
 * stores the corresponding values in the local storage.
 * @returns {boolean} - Returns true if the URL has the hash parameter, false otherwise.
 */
function checkHashAstralPortrait() {
    let hasHash = false;
    let urlParams = new URLSearchParams(url.search);
    let hashAstralPortrait = urlParams.has("hash_astral_portrait") ? urlParams.get("hash_astral_portrait") : false;
    if (hashAstralPortrait) {
        var astralPortrait = JSON.parse(atob(hashAstralPortrait));
        localStorage.setItem('birthdayDate', astralPortrait.birthday.date);
        localStorage.setItem('birthdayCity', astralPortrait.city.name);
        localStorage.setItem('birthdayHour', astralPortrait.birthday.hour);
        localStorage.setItem('birthdayMin', astralPortrait.birthday.min);
        localStorage.setItem('astrologyLatitude', astralPortrait.city.latitude);
        localStorage.setItem('astrologyLongitude', astralPortrait.city.longitude);
        hasHash = true;
    }
    return hasHash;
}
