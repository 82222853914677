$(function () {
    $("#com_onglet").on("click", ".onglet", function (evt) {

        if ($("a", this).length && !$(this).hasClass("actif")) {

            $(this).addClass("actif").siblings().removeClass("actif");
        }
    });

    if ($('.fr .characteristics__list.characteristics__list--compositions .characteristics__item:is(.dimensions-savon, .boite-a-savon)').length) {
        $('.fr .characteristics__list.characteristics__list--compositions .characteristics__item:is(.dimensions-savon, .boite-a-savon)').appendTo('.characteristics__list.characteristics__list--details div');
    }

    if ($('.en .characteristics__list.characteristics__list--compositions .characteristics__item:is(.soap-dimensions, .packaging)').length) {
        $('.en .characteristics__list.characteristics__list--compositions .characteristics__item:is(.soap-dimensions, .packaging)').appendTo('.characteristics__list.characteristics__list--specifications div');
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
                $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
                $('.misc_cookiesinfo #shadCookie').removeClass('actif');
                $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').removeClass('loading');
            }, 500);
        }
    });
});

window.setTimeout(function () {
	if ($("#header_mention_id").length) {
		$("body").removeClass("no_bandeau");
	} else {
		$("body").addClass("no_bandeau");
	}

    if ($(".push_container").length) {
        $(".push_container").each(function () {
            $(this).appendTo(".left_side.elem_tunnel").fadeIn('slow');
        });
    }

}, 500);

function changeLocale(locale, uri, site) {
    $.ajax({
        url: path_relative_root + 'ajax_get_uri_by_lang.php',
        type: 'post',
        data: {
            lang: locale,
            url: uri,
            site: site
        },
        success: function (data) {
            if (data != "") {
                var force = "/";
                if (uri == "/") {
                    force = "?force";
                }

                location.href = data + force;
            }
        }
    });
}

/* STORE LOC */
// Creation d'un bloc correspondant a un marqueur sur la liste laterale
function creerLigneListe(cpt, id, nom, adresse, ville, cp, pays, currently_open, horaire_today, distance, marqueur, picture_filtre, magasinId, stock, stockStatus, stock_quantity, clientId, favorite, beContacted, flag_available, url, shop_filter, telephone, query, lat, lng)
{
    var info_horaire_today = horaire_today;
    currently_open = parseFloat(currently_open);
    var li = document.createElement('li');
    li.id = id;

    li.className = 'elem-list-store';

    if (lat != "" && lng != "" && !$('body.product_page').length) {
        li.setAttribute("onclick", "geolocGoto('" + lat + "', '" + lng + "')");
    }


    /***** Product stock infos *****/
    if (stock_quantity == 'undefined' || stock_quantity == '') {
        stock_status_msg = 'unknow_stock';
    } else {
        var in_stock = (stock_quantity >= 6 && stock_quantity <= 9999);
        var limited_stock = (stock_quantity >= 1 && stock_quantity <= 5);
        var not_in_stock = stock_quantity == 0;
        var to_be_confirmed_stock = stock_quantity == 10000;
        var can_be_ordered_stock = stock_quantity == 11000;

        var stock_status_msg = '';
        if (in_stock) {
            stock_status_msg = 'in_stock';
        }
        if (limited_stock) {
            stock_status_msg = 'limited_stock';
        }
        if (not_in_stock) {
            stock_status_msg = 'not_in_stock';
        }
        if (to_be_confirmed_stock) {
            stock_status_msg = 'to_be_confirmed_stock';
        }
        if (can_be_ordered_stock) {
            stock_status_msg = 'can_be_ordered_stock';
        }
    }

    var class_horaire_today = currently_open ? 'store_open' : 'store_closed';
    var ligneListe = '<div class="elem_list_contents">' + '<div class="nom_store">';

    if (distance) {
        ligneListe += '<h2 class="title">' + nom +  '<p class="distance">' + '('+ distance + 'km)' + '</p></h2>';
    } else {
        ligneListe += '<h2 class="title">' + nom + '</h2>';
    }

    ligneListe += '</div>' + '<div class="content_store">';

    if ($('body.product_page').length) {
        ligneListe += `<div class="detail_store" onclick="location.href = '${path_relative_root + url}'">`
    } else {
        ligneListe += '<div class="detail_store">'
    }
    
    ligneListe += `<span class="address_store">
                      <span class="rue_store">${adresse.toLowerCase()}, </span>
                      <span class="ville_store">${cp && cp !== '0' ? cp : ''} ${ville.charAt(0).toUpperCase() + ville.slice(1).toLowerCase()}</span>
                  </span>
                  <a href="https://www.google.com/search?q=adresse+${nom}" target="_blank"  onclick="DataLayerTrigger.storeLocator('${nom}', '${pays}', '${ville}')" >
                  <span class="tel_store">${telephone}</span>
                  </a>`;

    var is_page_product = $('body.product_page').length == 1;

    ligneListe += '</div>';

    ligneListe += '<div class="col-2"><p class="horaire_today puce_' + class_horaire_today + '">' + info_horaire_today + '</p></div>';
    
    if (document.getElementById('produit_id')) {

        /***** Product stock infos msg *****/
        if (stock != 'undefined') {
            ligneListe += '<p class="stock_status ' + stock_status_msg + '">' + Translator.translate(stock_status_msg + '_msg') + '</p>';
        }
    }
    if (is_page_product) {

        if (beContacted == 1) {
            if ($('body.product_page.mobile').length) {
                ligneListe += `<div class="availableTxt store_more" onclick="handleChangeMobile(${id})"> <span>${txt_available_mag}</span> </div> </div> </div>`;
            } else {
                var nom_mag = nom.replace(/'/g, "\\'");
                ligneListe += `<div class="availableTxt store_more" onclick="handleChange('${id}','${nom_mag}')"> <span>${txt_available_mag}</span> </div> </div> </div>`;
            }
        } else {
            ligneListe+= '</div>' + '</div>';
        }
    }

    if (!is_page_product) {
        ligneListe += `
            <div class="learn_more_store">
                <a onclick="createDataLayer(${id})" href="${path_relative_root}${url}">${Translator.translate('learn_more')}<i class="arrow-down"></i></a>
            </div>`;
    }

    ligneListe+= '</div>' + '</div>' + '</div>';

    li.innerHTML = ligneListe;

    li.addEventListener('click', function () {
        google.maps.event.trigger(marqueur, 'click');
    });


    return li;
}

function creerMarqueur(point, nom, adresse, ville, cp, pays, horaire, telephone, email, site, image_mag, type_mag, cpt, magasin_id, url, distance, horaire_today, currently_open)
{
    var img = path_relative_root + "img/maps/marker.png";
    var http = new RegExp("^http://", "g");

    // Origins, anchor positions and coordinates of the marker
    // increase in the X direction to the right and in
    // the Y direction down.
    var image = new google.maps.MarkerImage(img,
        // This marker is 20 pixels wide by 32 pixels tall.
        new google.maps.Size(55, 70),
        // The origin for this image is 0,0.
        new google.maps.Point(0,0));

    // The anchor for this image is the base of the flagpole at 0,32.
    //new google.maps.Point(10, 26));
    var shadow = new google.maps.MarkerImage(path_relative_root + 'img/indic_carte_ombre.png',
        // The shadow image is larger in the horizontal dimension
        // while the position and offset are the same as for the main image.
        new google.maps.Size(39, 53),
        new google.maps.Point(0,0),
        new google.maps.Point(10,0));
    // Shapes define the clickable region of the icon.
    // The type defines an HTML <area> element 'poly' which
    // traces out a polygon as a series of X,Y points. The final
    // coordinate closes the poly by connecting to the first
    // coordinate.

    var marqueur = new google.maps.Marker({
        position: point,
        icon: image
    });

    marqueur.set("magasin_id", magasin_id);

    nom = $.trim(nom);
    adresse = $.trim(adresse);
    ville = $.trim(ville);
    cp = $.trim(cp);
    pays = $.trim(pays);
    horaire = $.trim(horaire);
    telephone = $.trim(telephone);
    email = $.trim(email);
    site = $.trim(site);
    distance = Math.round(distance);
    var info_horaire_today = horaire_today;
    if (currently_open) {
        var class_horaire_today = 'store_open';
        var currently_open_info = Translator.translate('store_opened');
    } else {
        var class_horaire_today = 'store_close';
        var currently_open_info = Translator.translate('store_closed');
        info_horaire_today = '';
    }

    if (distance) {
        var ligneListe = '<div class="info_popup"><div class="name"><a href="' + url + '">' + nom + '</a><span class="dist">(' + distance + ' km)</span>';
    } else {
        var ligneListe = '<div class="info_popup"><div class="name"><a href="' + url + '">' + nom + '</a></div>';
    }

    if (image_mag != '') {
        ligneListe += '<img src="' + image_mag + '" />';
    }

    ligneListe += '<div class="adresse">' + adresse.toLowerCase() + '<br />' + (cp !== '0' ? cp + ' ' : '') + ville.toLowerCase() + '<br />' + pays.toLowerCase() + '</div>';

    if (telephone != '') {
        ligneListe += '<div class="coord">' + telephone + '</div>';
    }

    if (site != '') {
        if (!http.test(site)) {
            ligneListe += '<a href="http://' + site + '" class="site" target="_blank">' + site + '</a>';
        } else {
            ligneListe += '<a href="' + site + '" class="site" target="_blank">' + site + '</a>';
        }
    }

    if (horaire != '') {
        ligneListe += '<div class="horaire_title"><span class = "' + class_horaire_today + '">' + currently_open_info + '</span>' + '</div>';
        ligneListe += '<div class="horaire_info">' + horaire + '</div>';
    }

    ligneListe += '<div class="btn_voir_magasin"><a href="' + url + '">' + Translator.translate('learn_more') + '</a></div></div>';

    google.maps.event.addListener(marqueur, "click", function() {
        if (!infowindow) {
            infowindow = new google.maps.InfoWindow();
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        } else {
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        }
    });

    var detail_store_panel = document.getElementById("store_id_dataLayer");
    detail_store_panel.textContent = magasin_id

    markersArray.push(marqueur);

    return marqueur;

}

// Display all products in rayon
function displayAllProducts() {
    $('#list_item .item').show();
    $('.pageRayon .see_more').hide();
}

/**
 * Validates an email address.
 *
 * @param {string} email - The email address to validate.
 * @returns {boolean} - Returns true if the email is valid, otherwise false.
 */
function validateEmail(email) {
    var rex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    return rex.test(email);
}

if ($('.headerNav__list').length) {
    document.addEventListener('DOMContentLoaded', () => {
        const menuContainer = document.querySelector(".headerNavList");
        const menuItems = document.querySelectorAll('.headerNav__list .headerNav__item');
        const subItems = document.querySelectorAll('.headerNav__Sublist .headerNav__item');
    
        const clearActiveClasses = () => {
            subItems.forEach(subItem => subItem.classList.remove('active'));
            $('.header').removeClass('bg-active');
            closeMultiShad('shad_menu');
        };

        menuItems.forEach(menu => {
            const subItem = document.querySelector(`#sub_${menu.id}`);
            if (subItem) {
                menu.addEventListener('mouseenter', () => {
                    clearActiveClasses();
                    subItem.classList.add('active');
                    $('.header').addClass('bg-active');
                    openMultiShad('shad_menu');
                });

                menuContainer.addEventListener('mouseleave', () => {
                    clearActiveClasses();
                });
            }
        });
    });
}