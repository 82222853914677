function appendCreditCardLinkText() {
    var creditCardBlock = $('body.checkout #main_cart_wrapper .wrapper_bloc_banque.credit_card');

    if(creditCardBlock.length) {
        creditCardBlock.append('<p class="bank_subtitle_inactif"><span>' + Translator.translate('by_clicking_here') + '</span></p>');
    }
}

$(function() {

    $(".js-closeLightboxNewsletter").on('click',function() {
        $('#exit_overlay_OVI').removeClass('actif');
        $('#shad').removeClass('actif');

    })

    function toggleFooter() {
        $('.footerContent__left .col_content span').addClass('aft bgSprite arrow');
        $('.footerContent__left .col_content span').on('click', function() {
            $(this).toggleClass('open');
        })
    }

    toggleFooter();

    if ($(".bank_left_side").length > 0) {
        window.setTimeout(function () {
            $(".wrapper_bloc_banque").css('opacity', '1');
        }, 500);

        // Empêcher le volet "Par carte bancaire" d'ouvrir automatiquement
        $(".wrapper_bloc_banque.credit_card.flexco").removeClass('actif');

        // Ajouter "En cliquant ici" sous "Par carte bancaire"
        appendCreditCardLinkText();

        $(".wrapper_bloc_banque.credit_card").on('click touch', function(evt) {
            $(".bank_subtitle_inactif").addClass('disabled');
        });

        $('.wrapper_bloc_banque').each(function() {
            $(this).on('click touch', function(evt) {
                $(".wrapper_bloc_banque").removeClass('actif');
                $(this).addClass('actif');

                if (!$(this).hasClass("credit_card")) {
                    $(".bank_subtitle_inactif").removeClass('disabled');
                }
            });
        });
    }

    /***** Trigger shad menu *****/
    triggerHeaderShadMenu();

    /**** Small cart overlay *****/
    overlayScrollBarsSmallBasket();

    /********** HOMEPAGE **********/

    /***** Cover module *****/
    if ($('#coverModule .swiper-slide').length > 1) {
        var coverHomeSwiper = new Swiper('#coverModule', {
            preloadImages: false,
            lazy: true,
            loop: true,
            speed: 1500,
            autoplay: {
                delay: 3000,
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
        });

        $('#coverModule').hover(function() {
            (this).swiper.autoplay.stop();
        }, function() {
            (this).swiper.autoplay.start();
        });
    }

    /***** Top brands module *****/
    if ($('#brandsSwiper .swiper-slide').length > 1) {

        var coverHomeSwiper = new Swiper('#brandsSwiper', {
            slidesPerView: 2.48,
            centeredSlides: true,
            spaceBetween: 5,
            lazy: true,
            loop: true,
            speed: 1500,
            pagination: {
                el: ".swiper-pagination",
                type: "progressbar",
            },
            breakpoints: {
                1460: {
                    slidesPerView: 4.55,
                },
                1100: {
                    slidesPerView: 3.48,
                }
            }
        });
    };

    setTimeout(function() {
        if ($('#tgHomeSwiper .swiper-slide').length > 1) {
            var tgHomeSwiper = new Swiper('#tgHomeSwiper', {
                slidesPerView: 2,
                grid: {
                    rows: 2,
                    fill: 'row',
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        };
    },500);

    /********* Create elements **********/
    if ($('body.checkout #cart_total .wrapper_payment').length) {
        var $deja_compte = Translator.translate('tunnel_right_warning_text');
        $( 'body.checkout #cart_total .wrapper_payment' ).append( $('<p class="tunnel_right_warning_text">' + $deja_compte + '</p>') );
    }

    if ($('body.cart.step_2 .transporteur_info').length) {
        var $delivered_by = Translator.translate('tunnel_delivered_by');
        ( $('<span>' + $delivered_by + '</span>') ).insertBefore($('body.cart.step_2 .transporteur_info span'));
    }

    if ($('body.checkout .payment_addr_wrapper .address').length) {
        var $delivered_by = Translator.translate('tunnel_delivered_by');
        ( $('<span>' + $delivered_by + '</span>') ).insertBefore($('body.checkout .payment_addr_wrapper:first-child .address .name'));
    }

    /********* Moving elements *********/
    /* Lightbox NL */
    if ($('#formu_inscription_news .pop_cgv_listing').length) {
        $('#formu_inscription_news .pop_cgv_listing').insertBefore('#formu_inscription_news .w-submit');
    }

    /* Login title */
    if ($('body.body_login .newlogin_page .title.my_account .subtitle').length) {
        $('body.body_login .newlogin_page .title.my_account .subtitle').insertBefore('body.body_login .newlogin_page .login_form_wrapper .title');
    }

    if ($('#productInfos .productInfos__details').length && $('#astrologyFormWrapper').length) {
        $('#productInfos .productInfos__details').insertBefore('#astrologyFormWrapper');
    }

    /********* Wordings switches *********/
    /* NL form footer */
    if ($('.footer .w-newsletter-form').length) {
        var nlFormInputText = Translator.translate('nl_form_input');
        var nlFormCtaText = Translator.translate('nl_form_cta');

        $('.footer .w-newsletter-form .w-input-label span').html(nlFormInputText);
        $('.footer .w-newsletter-form .w-submit-button:not(.w-loader) span').html(nlFormCtaText);
    }

    if ($('.checkout .alias_list_wrapper').length) {
        var bankConfianceText = Translator.translate('payement_confiance_card');
        $('.checkout .alias_list_wrapper .bank_confiance').html(bankConfianceText + ' (<img src="' + path_relative_root + 'img/img_secure_transaction.gif">)');
    }

    /* FP alert stock input and button text */
    if ($('.w-alertestock-form').length) {
        var fpAlertStockInputText = Translator.translate('alert_stock_form_input');
        var fpAlertStockButtonText = Translator.translate('validate_ok');

        $('.w-alertestock-form .w-input-label span').html(fpAlertStockInputText);
        $('.w-alertestock-form .w-submit-button span').html(fpAlertStockButtonText);
    }

    /* Login */
    if ($('body.body_login .newlogin_page .title.my_account h2').length) {
        var loginTitleText = Translator.translate('my_account_title');

        $('body.body_login .newlogin_page .title.my_account h2').html(loginTitleText);
    }

    /* Login form */
    if ($('.w-newlogin-form .w-email-input .w-input-label span').length) {
        var loginEmailInputText = Translator.translate('email_address');

        $('.w-newlogin-form .w-email-input .w-input-label span').html(loginEmailInputText);
    }

    /*****  FP STICKY WRAPPER *****/
    if ($('.pageProduit__wrapper').length) {
        setSticky('.productInfos', '.productInfos');
    }

    /* Tunnel - faq */
    if ($('#faqTunnel__list').length) {
        toggleActive(document.querySelectorAll('.faqTunnel__question'));
        toggleActive(document.querySelectorAll('.faqTunnel__item .countryInfos'));
        toggleActive(document.querySelectorAll('.faqTunnel__item .subCountryInfos'));
    }

    // Brand's page with anchors
    $('.brand_result .brand_result_text a').click(function () {
        var linkHref = this.href;
        // if the link targets an anchor
        if (linkHref.includes('#')) {
            var word = linkHref.substr((linkHref.indexOf('#') + 1));
            var firstLetter = word.substr(0, 1).toUpperCase();
            if ($('[data-value=' + firstLetter + ']').length) {
                $('[data-value=' + firstLetter + ']').click();
                if ($('#' + word).length) {
                    $('#' + word).click();
                    $("html, body").animate({
                        scrollTop: $('#' + firstLetter).offset().top - 175
                    });
                }
            }
        }
    });
});

/***** Trigger shad menu *****/
function triggerHeaderShadMenu() {
    let shadMenuHoverableHeaderItems = document.querySelectorAll('.headerNav__item.hoverable');

    for (let hoverableItem of shadMenuHoverableHeaderItems) {
        let hoverableItemlink = hoverableItem.querySelector(".itemTitle");

        if (hoverableItemlink == null) continue;

        // action qui ouvre le menu de l'element hoverable
        hoverableItemlink.addEventListener("touchstart", function(e){
            e.preventDefault();
            closeMenu();
            hoverableItemlink.parentElement.classList.add("active");
            document.querySelector("#site_head_wrap").classList.add("bg-active");
            openMultiShad('shad_menu');
        });
        hoverableItemlink.parentElement.addEventListener('mouseenter', function() {
            closeMenu();
            hoverableItemlink.classList.add("active");
            document.querySelector("#site_head_wrap").classList.add("bg-active");
            openMultiShad('shad_menu');
        });

        // action qui ferme le menu des l'elements hoverable
        hoverableItemlink.parentElement.addEventListener('mouseleave', function() {
            closeMultiShad('shad_menu');
            closeMenu();
        });
    }

    // action qui ferme le menu des l'elements hoverable
    document.querySelector("#shad_menu").addEventListener("touchstart", closeMenu);
}

function closeMenu(){
    document.querySelector("#site_head_wrap").classList.remove("bg-active");
    closeMultiShad('shad_menu');
    for (let elem of document.querySelectorAll(".headerNav__item")){
        elem.classList.remove("active");
    }
}

/****** Brand ******/
function toggleBrandBloc() {
    /* Sticky des lettres de l'alphabet lors du scroll */
    const nav = document.querySelector('.brand_nav');
    function handleScroll () {
        if(window.scrollY >250){
            nav.classList.add('scrolling-sticky');
        } else {
            nav.classList.remove('scrolling-sticky');
        }
    }
    document.addEventListener('scroll', handleScroll);
    /* Fin Sticky */

    //Variables
    var letterList = document.querySelectorAll('#brandWrapper .brand_nav_item');
    var blocList = document.querySelectorAll('#brandWrapper .brand_bloc');
    var letterBlocList = document.querySelectorAll('#brandWrapper .brand_bloc .brand_letter');

    /* Initialisation à l'ouverture de la page marque */
    var index = 0;
    while(letterList[index].classList.contains('empty')){
        index++;
    }
    // Variable qui trouve la liste et le champs de la première lettre non vide
    var initialisationItemList = document.querySelectorAll('#brandWrapper #'+letterList[index].textContent+' .brand_list_item');
    var initialisationResultList = document.querySelectorAll('#brandWrapper #'+letterList[index].textContent+' .brand_result');
    // Activation de la premiere lettre ainsi que du premier élément de la liste de la lettre selectionnée
    letterList[index].classList.add('active');
    blocList[0].classList.remove('inactive');
    initialisationItemList[0].classList.add('active');
    // Affichage du descriptif du premier élement de la liste
    for(const result of initialisationResultList){
        result.classList.add('inactive');
    }
    initialisationResultList[0].classList.remove('inactive');
    //Selection d'un nouvel élément sur la liste à l'initialisation
    for (const item  of initialisationItemList){
        item.addEventListener('click', function(){
            var targetItemListId = this.dataset.value;
            // Activation de l'élément selctionnée
            for(const item of initialisationItemList){
                item.classList.remove('active');
            }
            this.classList.add('active');
            // Affichage du descriptif de l'élément sélectionné
            for(const result of initialisationResultList){
                if(result.id === targetItemListId){
                    result.classList.remove('inactive');
                    result.classList.add('fondu');
                }
                if(result.id !== targetItemListId){
                    result.classList.add('inactive');
                }
            }
        });
        item.addEventListener('mouseenter',function() {
            var targetIdHover = this.dataset.value;
            for(const result of initialisationResultList){
                if(result.id === targetIdHover && result.classList.contains('inactive')){
                    result.classList.add('hover');
                    result.classList.add('fondu');
                }
                if(result.id !== targetIdHover){
                    result.classList.add('no-hover');
                }
            }
        });
        item.addEventListener('mouseleave',function() {
            for(const result of initialisationResultList){
                result.classList.remove('no-hover');
                result.classList.remove('hover');
                result.classList.remove('fondu');
            }
        });
    }
    /* Fin Initialisation */

    /* Selection du nouvelle lettre */
    for(const letter of letterList){
        if (!letter.classList.contains('empty')) {
            letter.addEventListener('click', function() {
                var targetLetterId = this.dataset.value;
                for(const bloc of blocList){
                    bloc.classList.add('inactive');
                }
                var element = document.querySelector('#brandWrapper #'+targetLetterId);
                var offSet = element.offsetTop-250;
                window.scroll(0,offSet);
                var newListItem = document.querySelectorAll('#brandWrapper #'+targetLetterId+' .brand_list_item');
                var newResultItem = document.querySelectorAll('#brandWrapper #'+targetLetterId+' .brand_result');
                // Changement de la lettre selectionnée
                for(const letter of letterList){
                    letter.classList.remove('active');
                }
                this.classList.add('active');
                // Affichage des éléments du bloc de la lettre séléctionnée
                for(const bloc of blocList){
                    //Si l'id du bloc ne correspond pas à la lettre séléctionnée -> Bloc Inactif
                    if(bloc.id !== targetLetterId){
                        bloc.classList.add('inactive');
                    }
                    // Si l'id du bloc correspond à la lettre séléctionnée -> Bloc Actif
                    if(bloc.id === targetLetterId){
                        bloc.classList.remove('inactive');
                        // Activation du premier élément de la liste de la lettre sélectionnée
                        for(const item of newListItem){
                            item.classList.remove('active');
                        }
                        newListItem[0].classList.add('active');
                        // Affichage du descriptif du premier élement de la liste
                        for(const result of newResultItem){
                            result.classList.add('inactive');
                        }
                        newResultItem[0].classList.remove('inactive');
                    }
                }
                /* Selection d'un nouvel élement dans la liste de la lettre sélectionnée */
                for (const item of newListItem){
                    item.addEventListener('click', function(){
                        var targetItemListId = this.dataset.value;
                        // Activation de l'élément sélectionné
                        for(const item of newListItem){
                            item.classList.remove('active');
                        }
                        this.classList.add('active');
                        // Affichage du descriptif de l'élément sélectionné de la liste
                        for(const result of newResultItem){
                            if(result.id == targetItemListId){
                                result.classList.remove('inactive');
                                result.classList.add('fondu');
                            }
                            if(result.id !== targetItemListId){
                                result.classList.add('inactive');
                            }
                        }
                    });
                    item.addEventListener('mouseenter',function() {
                        var targetIdHover = this.dataset.value;
                        for(const result of newResultItem){
                            if(result.id === targetIdHover && result.classList.contains('inactive')){
                                result.classList.add('hover');
                                result.classList.add('fondu');
                            }
                            if(result.id !== targetIdHover){
                                result.classList.add('no-hover');
                            }
                        }
                    });
                    item.addEventListener('mouseleave',function() {
                        for(const result of newResultItem){
                            result.classList.remove('no-hover');
                            result.classList.remove('hover');
                            result.classList.remove('fondu');
                        }
                    });
                }
            });
        }
    }
    for( letterBloc of letterBlocList){
        letterBloc.addEventListener("click", function(){
            var targetLetterId = this.textContent;
            var listItemChoiceBlock = document.querySelectorAll('#brandWrapper #'+targetLetterId+' .brand_list_item');
            var resultItemChoiceBlock = document.querySelectorAll('#brandWrapper #'+targetLetterId+' .brand_result');
            // Activation de la lettre dans le bloc vertical de l'alphabet par rapport à la lettre choisi dans le bloc horizontal
            for(letter of letterList){
                if(targetLetterId == letter.dataset.value){
                    letter.classList.add("active");
                }
                if(targetLetterId != letter.dataset.value){
                    letter.classList.remove("active");
                }
            }
            for(const bloc of blocList){
                if(targetLetterId != bloc.id){
                    bloc.classList.add("inactive");
                }
                if(targetLetterId == bloc.id){
                    bloc.classList.remove("inactive");
                    // Activation du premier élément de la liste de la lettre sélectionnée
                    for(const item of listItemChoiceBlock){
                        item.classList.remove('active');
                    }
                    listItemChoiceBlock[0].classList.add('active');
                    // Affichage du descriptif du premier élement de la liste
                    for(const result of resultItemChoiceBlock){
                        result.classList.add('inactive');
                    }
                    resultItemChoiceBlock[0].classList.remove('inactive');
                }
            }
            /* Selection d'un nouvel élement dans la liste de la lettre sélectionnée */
            for (const item of listItemChoiceBlock){
                item.addEventListener('click', function(){
                    var targetItemListId = this.dataset.value;
                    // Activation de l'élément sélectionné
                    for(const item of listItemChoiceBlock){
                        item.classList.remove('active');
                    }
                    this.classList.add('active');
                    // Affichage du descriptif de l'élément sélectionné de la liste
                    for(const result of resultItemChoiceBlock){
                        if(result.id == targetItemListId){
                            result.classList.remove('inactive');
                            result.classList.add('fondu');
                        }
                        if(result.id !== targetItemListId){
                            result.classList.add('inactive');
                        }
                    }
                });
                item.addEventListener('mouseenter',function() {
                    var targetIdHover = this.dataset.value;
                    for(const result of resultItemChoiceBlock){
                        if(result.id === targetIdHover && result.classList.contains('inactive')){
                            result.classList.add('hover');
                            result.classList.add('fondu');
                        }
                        if(result.id !== targetIdHover){
                            result.classList.add('no-hover');
                        }
                    }
                });
                item.addEventListener('mouseleave',function() {
                    for(const result of resultItemChoiceBlock){
                        result.classList.remove('no-hover');
                        result.classList.remove('hover');
                        result.classList.remove('fondu');
                    }
                });
            }
            var element = document.querySelector('#brandWrapper #'+targetLetterId);
            var offSet = element.offsetTop-200;
            window.scroll(0,offSet);
        })
    }
    /* Fin selection du nouvelle lettre */
}

//define addMessage function here to avoid console error
function addMessage() {
    var formMotPerso = $('#mot_personnalise');

    if ($("#has_perso_word").prop('checked') === true) {
        formMotPerso.slideDown("slow");
        $('input[name=add_mot_perso]').val('ok');
    } else {
        formMotPerso.slideUp("speed");
        $('input[name=add_mot_perso]').val('no');
    }
}

//Demande client : fonction pour permettre l'enregistrement du message personalisé en session
function keepGiftMessage() {
    setTimeout(function(){
        if ($("#has_perso_word").prop('checked') === false) {
            $("#has_perso_word").prop('checked');
            $('input[name=add_mot_perso]').val('ok');
        }
        $('#mot_personnalise').slideDown("slow");

            /* keep gift message value in session storage */
            // Get the text area element
            let $textArea = $("#mot_personnalise_texte");

            // Check if data is already stored in local storage
            if (sessionStorage.getItem("textAreaData")) {
            // If data is stored, retrieve it and set it as the value of the text area
                $textArea.val(sessionStorage.getItem("textAreaData"));
            }

            // Listen for changes to the text area
            $textArea.on("input", function() {
            // Store the new data in local storage
                sessionStorage.setItem("textAreaData", $textArea.val());
            });
    }, 300);
}

if ($('.cart.step_1').length) {
    keepGiftMessage();
}

//permet le déclenchement de la fonction de sauvegarde du message perso à l'ajout produit du panier à la wishlist
$(window).on("wishToBasket_complete", function (data) {
    keepGiftMessage();
})

if (document.querySelector('body').classList.contains('marque_index')) {
    toggleBrandBloc();
}
/*  Change word of breacrumb login */
if (document.querySelector('body').classList.contains('body_login')) {
    var breadcrumbLogin = Translator.translate('breadcrumb_login');
    $('.body_login .breadcrumb.current').html(breadcrumbLogin);
}

/* Format Price */
const centPrice = document.querySelectorAll(".number_cent");

for (const price of centPrice){
    if(price.textContent != ",00"){
        price.classList.add("active")
    }
}

function scrollToTop() {
    $("html, body").animate({
        scrollTop: 200
    }, 200);
}

function setSticky(waypointTarget, stickyElTarget) {
    $(window).on("scroll", function() {
        header = $('#site_head_wrap');
        scrollDistance = $(window).scrollTop();
        stickyWaypoint = $(waypointTarget).height();
        offset = 30;

        if (scrollDistance >= stickyWaypoint) {
            $(stickyElTarget).addClass('sticky');

            if ($('#productInfos .productInfos__details').length && $('.productInfos__form .productInfos__desc').length) {
                $('#productInfos .productInfos__details').insertBefore('.productInfos__form .productInfos__desc');
                $("#astrologyShowButton").attr("onclick","scrollToTop();displayAstralPortraitForm()");
                $("#astrologySubmitButton").attr("onclick","scrollToTop();calculateAstralPortrait()()");
            }

        } else {
            $(stickyElTarget).removeClass('sticky');

            if ($('#productInfos .productInfos__details').length && $('#astrologyFormWrapper').length) {
                $('#productInfos .productInfos__details').insertBefore('#astrologyFormWrapper');
            } else {
                $('#productInfos .productInfos__details').insertAfter('#productInfos .productInfos__desc');
            }
            $("#astrologyShowButton").attr("onclick","displayAstralPortraitForm()");
            $("#astrologySubmitButton").attr("onclick","calculateAstralPortrait()");
        }
    });
}

function updateCartElements() {
    setTimeout(() => {
        /**
         * Update cart rollover scroll bar
         */
        overlayScrollBarsSmallBasket();
        keepGiftMessage();

        /**
         * Update fas tunnel behaviour
         */
        if ($('#faqTunnel__list').length) {
            toggleActive(document.querySelectorAll('.faqTunnel__question'));
            toggleActive(document.querySelectorAll('.faqTunnel__item .countryInfos'));
        }
    }, 1000);
}

function overlayScrollBarsSmallBasket() {
    // ROLLOVER SCROLLBARS
    if ($('#cart_list.smallCart__list').length) {
        if ($('#cart_list .smallCart__item').length > 2) {
            $("#cart_list.smallCart__list").overlayScrollbars({});
        }
    }
}

function toggleActive(arrayItems) {

    for (var i = 0; i < arrayItems.length; i++) {
        var element = arrayItems[i];

        element.addEventListener("click", function() {
            var currentElement = event.currentTarget;

            if (currentElement.classList.contains('countryInfos--europe') && currentElement.classList.contains('active')) {
                return false;
            }

            for (var j = 0; j < arrayItems.length; j++) {
                if (arrayItems[j] !== this) {
                    arrayItems[j].classList.remove('active');
                }
            }

            if (this.classList.contains('active')) {
                this.classList.remove('active');
            } else {
                this.classList.add('active');
            }

        });
    }
}
